

import { Vue, Component } from 'vue-property-decorator'

@Component
export default class Disease extends Vue {
  private searchInfo = {
    diseasesName: '',
    diseasesType: '',
    happenTime: ''
  }

  private loading = false
  private tableData = []
  private total = 0
  private page = 1
  private size = 10
  private typeList = []
  private monthList = ['一月', '二月', '三月', '四月', '五月', '六月', '七月', '八月', '九月', '十月', '十一月', '十二月']

  created () {
    this.loadData()
    this.getTypeList()
  }

  activated () {
    this.onSearch()
  }

  onSearch () {
    this.page = 1
    this.loadData()
  }

  loadData () {
    this.loading = true
    this.$axios.get(this.$apis.pestControl.selectYhDiseasesByPage,
      {
        ...this.searchInfo,
        page: this.page,
        size: this.size
      }).then(res => {
      this.tableData = res.list || []
      this.total = res.total || 0
    }).finally(() => {
      this.loading = false
    })
  }

  getTypeList () {
    this.$axios.get(this.$apis.common.selectDicByList, { dicType: 'diseasesType' }).then(res => {
      this.typeList = res.diseasesType || []
    })
  }

  onAdd () {
    this.$router.push({ name: 'diseaseAdd' })
  }

  onEdit (diseasesId: string) {
    this.$router.push({
      name: 'diseaseEdit',
      params: {
        diseasesId: diseasesId
      }
    })
  }

  onDetail (diseasesId: string) {
    this.$router.push({
      name: 'diseaseDetail',
      params: {
        diseasesId: diseasesId
      }
    })
  }

  onDelete (diseasesId: string) {
    this.$confirm('确认删除吗, 是否继续?', '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    }).then(() => {
      this.$axios.post(this.$apis.pestControl.deleteYhDiseases, { diseasesId }).then(() => {
        this.$message.success('删除成功')
        this.onSearch()
      })
    })
  }
}
